import { VueWizard } from '@/vue-wizard';
import { Component, Vue } from 'vue-property-decorator';
import { IUser } from '@/app/store/modules/user/user.models';
import { store, storeTypes } from '@/app/store';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

import $ from 'jquery';
import AgentDetails from '@/app/components/agents-management/dedicated-components/agent-details/AgentDetails.vue';
import PopConfirm from '@/app/components/shared/pop-confirm/PopConfirm.vue';
import ConversationModal from '@/app/components/shared/conversation-modal/ConversationModal.vue';
import ConversationListModal from '@/app/components/shared/conversation-list-modal/ConversationListModal.vue';
import UserNameModal from '@/app/components/agents-management/dedicated-components/user-name-modal/UserNameModal.vue';
import AgentCardDeck from '@/app/components/agents-management/dedicated-components/agent-card-deck/AgentCardDeck.vue';
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue';
import ConversationTransfer from '@/app/components/agents-management/conversation-transfer/ConversationTransfer.vue';
import { Agent } from '@/app/store/modules/admin/admin.models';
const _ = require('lodash');

@Component({
  name: 'agents-administrator',
  components: {
    PopConfirm,
    ConversationModal,
    ConversationListModal,
    UserNameModal,
    AgentCardDeck,
    AgentDetails,
    AgentCreateForm,
    ConversationTransfer,
  },
})
export default class AgentsAdministrator extends VueWizard {
  public modalConversation = false;
  public emptyText = { emptyText: 'Sin conversaciones' };
  public EventBus: Vue = new Vue();
  public visible = false;
  public modalvisible = false;
  public usrData: any = [];
  public agentDetails: Agent = {
    _id: '',
    user: '',
    disable: false,
    projects: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    __v: 0,
    company: '',
    deletedAt: null,
    subscriptions: [],
    usrage: [],
  };
  public agentConversationActive = 0;
  public projectTopics: any = [];
  public agentName = '';
  public user: IUser | null = null;
  public userLoged: IUser | null = store.state.user!.user;
  public edit = false;
  public agentNewName = '';
  public agentNewLastName = '';
  public agentNewPassword = '';

  public loadingInfo: boolean = false;

  public pStyle: any = {
    marginBottom: '16px',
    textTransform: 'capitalize',
    letterSpacing: '1px',
  };

  public pStyle2: any = {
    marginBottom: '24px',
  };

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }
  get agentConversations() {
    const closeConversations = this.agentCloseConversations;
    const openConversations = this.agentOpenConversations;
    const conversations = [
      ...openConversations,
      ...closeConversations.reverse(),
    ];
    return _.uniqBy(conversations, 'currentConsumer');
  }
  get agentOpenConversations() {
    return store.state.agent!.agentOpenConversations;
  }

  get agentCloseConversations() {
    return store.state.agent!.agentCloseConversations;
  }

  get currentCloseConversations(): any {
    return store.state.agent!.agentCloseConversations.filter(
      conversation =>
        conversation.currentConsumer ==
        store.state.conversation!.currentConversation.currentConsumer,
    );
  }

  showNewAgentModal(): void {
    this.modalvisible = true;
  }

  toggleVisible(): void {
    this.visible = !this.visible;
  }

  get projectAgent() {
    return store.state.admin!.project;
  }
  async showAgentDetails(values: any): Promise<void> {
    const { agentId, agentProjects: project, agentUsername: agent } = values;

    this.user = null;
    this.projectTopics = [];
    this.agentConversationActive = 0;
    store.commit(storeTypes.agent.mutations.reSetAgentCloseConversations(''));
    this.getProjectTopics(project);

    const url = `${BASE_URL_MANAGER}/agent/${agentId}`;
    try {
      const res = await Axios(url);
      this.agentDetails = res.data.agent;
      await this.getUserAgentData(this.agentDetails.user);
      await this.getConversationOpen(this.agentDetails._id);
      this.visible = true;
    } catch (error) {
      console.error(error);
    }

    this.agentName = agent;
  }
  async showAgentConversations(values: any): Promise<void> {
    const { agentId, agentProjects: project, agentUsername: agent } = values;

    this.user = null;
    this.projectTopics = [];
    store.commit(storeTypes.agent.mutations.reSetAgentCloseConversations(''));
    this.getProjectTopics(project);

    const url = `${BASE_URL_MANAGER}/agent/${agentId._id}`;
    try {
      const res = await Axios(url);
      this.agentDetails = res.data.agent;
      await store.dispatch(
        storeTypes.agent.actions.loadAgentCloseConversations({
          agent_id: agentId._id,
          page: 0,
        }),
      );
      await store.dispatch(
        storeTypes.agent.actions.loadAgentOpenConversations({
          agent_id: agentId._id,
          page: 0,
          limit: 50,
        }),
      );
      this.getUserAgentData(this.agentDetails.user);
      this.visible = true;
    } catch (error) {
      console.error(error);
    }

    this.agentName = agent;
  }

  onClose(): void {
    this.visible = false;
  }

  get agtUsrData() {
    return store.state.admin!.agents;
  }
  get getAgentProject() {
    return store.state.admin!.project;
  }
  async changeUserInfo(values: any): Promise<void> {
    const { agentNewName, agentNewLastName, agentNewPassword, userId } = values;

    if (this.user && this.user.name) {
      this.user.name.firstName = agentNewName;
      this.user.name.lastName = agentNewLastName;

      this.agtUsrData.map((agent: any) => {
        if (agent.usrage[0]._id == this.user!._id) {
          agent.usrage[0].name.firstName = this.user!.name!.firstName;
          agent.usrage[0].name.lastName = this.user!.name!.lastName;
        }
      });

      const dataToSend: any = {
        name: {
          firstName: this.user.name.firstName,
          lastName: this.user.name.lastName,
        },
      };
      if (agentNewPassword) {
        dataToSend.password = agentNewPassword;
      }

      const url = `${BASE_URL_MANAGER}/user/${userId._id}`;
      try {
        const resp = await Axios.put(url, dataToSend);
        if (resp.data.success === true) {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Agente modificado exitosamente',
            description: 'Los datos han sido guardados',
          });
          this.getUserAgentData(resp.data.user._id);
        } else {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Error',
            description:
              'Ha ocurrido un error al guardar los datos ' + resp.data!.message,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  async getProjectTopics(projectId: string): Promise<void> {
    const url = `${BASE_URL_MANAGER}/topic/${projectId}`;
    try {
      const res = await Axios(url);
      this.projectTopics = res.data.projectTopics;
    } catch (error) {
      console.error(error);
    }
  }

  async getAgentData(agent_id: string): Promise<void> {
    const url = `${BASE_URL_MANAGER}/agent/${agent_id}`;
    try {
      const res = await Axios(url);
      this.agentDetails = res.data.agent;
    } catch (error) {
      console.error(error);
    }
  }
  async getUserAgentData(user_id: any): Promise<void> {
    const url = `${BASE_URL_MANAGER}/user/${user_id._id}`;
    try {
      const res = await Axios(url);
      this.user = res.data.user;
      if (this.user && this.user.name) {
        this.agentNewName = this.user.name.firstName;
        this.agentNewLastName = this.user.name.lastName;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getConversationOpen(agent_id: string): Promise<void> {
    const url = `${BASE_URL_MANAGER}/agent/conversationOpen/${agent_id}`;
    try {
      const res = await Axios(url);
      this.agentConversationActive = res.data.conversation;
    } catch (error) {
      console.error(error);
    }
  }
  async unSuscribeAgent(values: any): Promise<void> {
    const { topic, agent } = values;

    this.loadingInfo = true;
    const url = `${BASE_URL_MANAGER}/topic/${topic._id}/agents/${agent._id}`;
    try {
      await Axios.delete(url);
      this.$notification.open({
        message: 'Se ha eliminado la suscripcion del topico al agente',
        description: 'El agente ha sido eliminado con exito del topico',
        style: {
          background: '#32ad3f',
          color: '#fff',
        },
      });

      await this.getAgentData(agent._id);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingInfo = false;
    }
  }

  async suscribeAgent(values: any): Promise<void> {
    const { topic, agent } = values;

    this.loadingInfo = true;
    const url = `${BASE_URL_MANAGER}/topic/${topic._id}/agents/${agent._id}`;
    try {
      await Axios.patch(url);
      this.$notification.open({
        message: 'Se ha suscrito el agente',
        description: 'El agente ha sido suscrito con exito al topico',
        style: {
          background: '#32ad3f',
          color: '#fff',
        },
      });

      await this.getAgentData(agent._id);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingInfo = false;
    }
  }

  elementDisable(elementId: string): void {
    const modal: any = $(elementId);

    modal.modal('hide');
  }

  async disableAgent(
    disableAgentId: string,
    project: string,
    agent: any,
    state: boolean,
  ): Promise<void> {
    const url = `${BASE_URL_MANAGER}/agent/${disableAgentId}/disable/${state}`;
    try {
      await Axios.put(url);
      const result = this.agtUsrData.filter(
        (agent: any) => agent._id === disableAgentId,
      );
      result[0].disable = state;
      this.agentDetails.disable = state;
      this.$notification.open({
        message: 'Estado del agente cambiado',
        description: 'El estado del agente cambio con exito!',
        style: {
          background: '#009dff',
          color: '#ffffff',
        },
      });

      this.showAgentDetails({
        agentId: disableAgentId,
        agentProjects: project,
        agetnUsername: agent,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteAgent(
    disableAgentId: string,
    project: string,
    agent: any,
  ): Promise<void> {
    const url = `${BASE_URL_MANAGER}/agent/${disableAgentId}/delete`;
    try {
      const res = await Axios.delete(url);
      if (res.data.success === true) {
        this.$notification.open({
          message: 'El agente ha sido eliminado',
          description: 'Agente eliminado con exito!',
          style: {
            background: '#009dff',
            color: '#ffffff',
          },
        });
        this.elementDisable('modalAgentDetails');
        await store.dispatch(
          storeTypes.admin.actions.getAgents(
            store.state.user!.user.company!._id,
          ),
        );
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información`,
          description: `Error: `,
        });
      }
    } catch (error) {
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al guardar al información`,
        description: `Error: ${error}`,
      });
    }
  }

  get msg(): string {
    let msg = 'Eliminará el agente \n ¿Desea continuar?';
    if (this.agentConversationActive > 0) {
      msg = `El agente tiene ${this.agentConversationActive} conversaciones activas \n Si elimina el agente, las conversaciones pasaran automaticamente a Resueltas \n ¿Desea continuar?`;
    }
    return msg;
  }

  get getAgentInfo() {
    return store.state.admin!.agents;
  }

  getSuscriptionClass(item: any, name: string): any {
    const subscriptions = item.subscriptions;

    const foundSubscription = subscriptions.find((subscription: string) => {
      const unless = subscription.split('_').slice(1).join('_');
      return name === unless;
    });

    return foundSubscription ? 'ant-btn-primary' : '';
  }
  existsUserName(): boolean {
    return this.user != null;
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  openAgentCreateModal(id: string) {
    this.EventBus.$emit('reload-modal');
    this.enable(id);
  }

  enable(id: string) {
    $('#' + id).modal('show');
  }
}

import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import $ from 'jquery';

@Component({ name: 'pop-confirm' })
export default class PopConfirm extends VueWizard {
  @Prop() readonly text!: string;
  @Prop({ default: 'Aceptar' }) okText!: string;
  @Prop({ default: 'Cancelar' }) cancelText!: string;
  @Prop() readonly elementId!: string;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string;
  @Prop({ default: true }) readonly disableEnable: boolean | undefined;

  disable() {
    $('#' + this.elementId).modal('hide');
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');
    if (this.disableEnable === true) {
      modal = $(enableId);
      modal.modal('show');
    }
  }

  executeCancel() {
    if (this.autoDisable) {
      this.disable();
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
    this.$emit('cancel');
  }
  executeAccept() {
    if (this.autoDisable) {
      this.disable();
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
    this.$emit('ok');
  }
}

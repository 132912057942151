import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ name: 'agent-card' })
export default class AgentCard extends VueWizard {
  @Prop() readonly agent!: any;
  @Prop() readonly detailsTarget!: string;
  @Prop() readonly chatsTarget!: string;

  getInitials(name: any) {
    let firstLetter = '';
    let secondLetter = '';
    const { firstName, lastName } = name;
    if (firstName) {
      firstLetter = firstName.charAt(0).toUpperCase();
    }
    if (lastName) {
      secondLetter = lastName.charAt(0).toUpperCase();
    }
    return firstLetter + secondLetter;
  }

  showDetails() {
    this.$emit('details', {
      agentId: this.agent._id,
      agentProjects: this.agent.projects,
      agentUsername: this.agent.usrage[0].username,
    });
  }
  showChats() {
    this.$emit('chats', {
      agentId: this.agent,
      agentProjects: this.agent.projects,
      agentUsername: this.agent.usrage[0].username,
    });
  }
}

import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
// Components
import AgentsAdministrator from '@/app/components/agents-management/agents-administrator/AgentsAdministrator.vue';
import AgentsAdministratorAdmin from '@/app/components/agents-management/agents-administrator-admin/AgentsAdministratorAdmin.vue';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import QuestionsManagement from '@/app/components/agents-management/questions-management/QuestionsManagement.vue';
import NavBar from '@/app/components/shared/nav-bar/NavBar.vue';
import { store } from '@/app/store';

@Component({
  name: 'agents-management',
  components: {
    AgentsAdministrator,
    AgentsAdministratorAdmin,
    AdminNavbar,
    QuestionsManagement,
    NavBar,
  },
})
export default class AgentsManagement extends VueWizard {
  public activeWindow = 1;
  public user: any = {};
  created() {
    this.user = store.state.user;
  }
}

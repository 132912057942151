import { VueWizard } from '@/vue-wizard';
import { Component, Prop } from 'vue-property-decorator';
import CheckBox from '@/app/components/shared/check-box/CheckBox.vue';
import ImageHolder from '@/app/components/shared/image-holder/ImageHolder.vue';
import FileHolder from '@/app/components/shared/file-holder/FileHolder.vue';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

@Component({
  name: 'quick-response-row',
  components: { CheckBox, ImageHolder, FileHolder },
})
export default class QuickResponseRow extends VueWizard {
  @Prop() readonly quickResponse!: any;
  @Prop() readonly editElementId!: string;
  @Prop() readonly topics!: any;

  public displayDetails: boolean = false;

  deleteQuickResponse() {
    let url = `${BASE_URL_MANAGER}/quickResponse/topic/${this.quickResponse._id}`;
    Axios.delete(url).then(res => {
      if (res.status == 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Respuesta rápida eliminada exitosamente',
          description: 'Los datos han sido borrados',
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
    });
    this.$emit('updated');
  }

  toggleDetails() {
    this.displayDetails = !this.displayDetails;
  }

  triggerEdit(editField: string) {
    this.$emit('edit', {
      quickResponse: this.quickResponse,
      id: this.editElementId,
      field: editField,
    });
  }

  get subString() {
    let substring = this.quickResponse.messages[0];
    if (substring.length > 200) {
      substring = substring.substring(0, 201) + '...';
    }
    return substring;
  }

  get topicName() {
    let names: any[] = [];
    this.topics.forEach((topic: { _id: any; name: any }) => {
      if (this.quickResponse.topic.includes(topic._id)) {
        names.push(topic.name);
      }
    });
    return names;
  }

  checked(id: string) {
    if (this.quickResponse.topic.includes(id)) {
      return true;
    }
    return false;
  }

  addTopic(id: string) {
    this.quickResponse.topic.push(id);
    this.updateTopics();
  }

  deleteTopic(id: string) {
    this.quickResponse.topic = this.quickResponse.topic.filter(
      (topic: any) => topic !== id,
    );
    this.updateTopics();
  }

  async updateTopics() {
    const url = `${BASE_URL_MANAGER}/quickResponse/topic/${this.quickResponse._id}`;
    try {
      const res = await Axios.put(url, this.quickResponse);
      if (res.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Tópico actualizado exitosamente',
          description: 'Los datos han sido guardados',
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
    } catch (err) {
      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: err.message,
      });
    }
  }
}

import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import $ from 'jquery';
import ConversationContent from '@/app/components/conversation/conversationContent/ConversationContent.vue';

//import { BModal } from 'bootstrap-vue'
//Vue.component('b-modal', BModal)
@Component({
  name: 'conversation-modal',
  components: { ConversationContent },
})
export default class ConversationModal extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop() readonly currentConversation!: any;
  @Prop() readonly eventBus!: any;
  @Prop() readonly agentConversations!: any;
  @Prop() readonly currentCloseConversations!: any;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string;

  businessStatuses: any = [];
  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
  }

  closeConversationModal() {
    this.disable();
    $('#' + this.elementId).modal('hide');
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  getUserPicture(item: any): any {
    const conversation = item.variables
      ? item
      : this.agentConversations.find(
          (conv: any) => conv._id == item.conversation,
        );
    const profileurl = conversation!.variables!.CONSUMER_PROFILE_PICTURE!.value;
    return profileurl;
  }
}

import { VueWizard } from '@/vue-wizard';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IUser } from '@/app/store/modules/user/user.models';
import { store } from '@/app/store';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

import $ from 'jquery';
import AgentDetails from '@/app/components/agents-management/dedicated-components/agent-details/AgentDetails.vue';
import PopConfirm from '@/app/components/shared/pop-confirm/PopConfirm.vue';
import ConversationModal from '@/app/components/shared/conversation-modal/ConversationModal.vue';
import ConversationListModal from '@/app/components/shared/conversation-list-modal/ConversationListModal.vue';
import UserNameModal from '@/app/components/agents-management/dedicated-components/user-name-modal/UserNameModal.vue';
import AgentCardDeck from '@/app/components/agents-management/dedicated-components/agent-card-deck/AgentCardDeck.vue';
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue';

const _ = require('lodash');

@Component({
  name: 'agents-administrator-admin',
  components: {
    PopConfirm,
    ConversationModal,
    ConversationListModal,
    UserNameModal,
    AgentCardDeck,
    AgentDetails,
    AgentCreateForm,
  },
})
export default class AgentsAdministratorAdmin extends VueWizard {
  public modalConversation = false;
  public emptyText = { emptyText: 'Sin conversaciones' };
  public EventBus: Vue = new Vue();
  public visible = false;
  public modalvisible = false;
  public usrData: any = [];
  public agtUsrData: any = [];
  public agentDetails: any = [];
  public projectAgent: any = [];
  public projectTopics: any = [];
  public agentName = '';
  public user: IUser | null = null;
  public userLoged: IUser | null = store.state.user!.user;
  public edit = false;
  public agentNewName = '';
  public agentNewLastName = '';
  public agentNewPassword = '';

  public loadingInfo: boolean = false;

  public pStyle: any = {
    marginBottom: '16px',
    textTransform: 'capitalize',
    letterSpacing: '1px',
  };

  public pStyle2: any = {
    marginBottom: '24px',
  };

  async mounted(): Promise<any> {
    await this.getAgentInfo();
  }

  public columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name.firstName',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      scopedSlots: { customRender: 'company' },
    },

    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];
  public agents: any = [];
  public searchAgent = '';
  public original_data_source_agents = [
    {
      _id: '',
      username: '',
      name: '',
      company: '',
    },
  ];

  public form = {
    name: '',
    lastName: '',
    password: '',
  };

  public rules = {
    name: [
      {
        required: true,
        message: 'Por favor ingrese el nombre',
        trigger: 'blur',
      },
    ],
    lastName: [
      {
        required: true,
        message: 'Por favor ingrese el apellido',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'Por favor ingrese la contraseña',
        trigger: 'blur',
      },
    ],
  };

  public agentEditModal: boolean = false;

  toggleVisible(): void {
    this.visible = !this.visible;
  }

  onClose(): void {
    this.visible = false;
  }

  async getAgentInfo() {
    try {
      this.loadingInfo = true;
      const response = await Axios(
        `${BASE_URL_MANAGER}/agent/users/agents/admins`,
      );
      // validate if company is not null

      this.agtUsrData = response.data.agents;
      this.original_data_source_agents = [...this.agtUsrData];
      this.loadingInfo = false;
    } catch (error) {
      console.log(error);
    }
  }
  getCompanyInfo(company: any): string {
    // if company.name different to undefined then return company.name else return company
    if (company === undefined || company === null || company === '')
      return '--';

    return company.name;
  }
  changeUserInfo(values: any): void {
    this.agentNewName = values.name;
    this.agentNewLastName = values.lastName;
    this.agentNewPassword = values.password;

    let user = this.user;

    if (!this.agentNewPassword || this.agentNewPassword == '') {
      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'La contraseña no puede estar vacia',
      });
      return;
    }

    const dataToSend = {
      name: {
        firstName: this.agentNewName,
        lastName: this.agentNewLastName,
      },
      password: this.agentNewPassword,
    };

    Axios.put(`${BASE_URL_MANAGER}/user/${user!._id}`, dataToSend).then(
      async resp => {
        if (resp.data.success === true) {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Agente modificado exitosamente',
            description: 'Los datos han sido guardados',
          });
        } else {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Error',
            description:
              'Ha ocurrido un error al guardar los datos ' + resp.data!.message,
          });
        }
      },
    );
  }

  elementDisable(elementId: string): void {
    const modal: any = $(elementId);
    modal.modal('hide');
  }

  openAgentEditModal(agent: any): void {
    console.log('agent', agent);

    this.agentNewName = agent.name.firstName;
    this.agentNewLastName = agent.name.lastName;
    this.agentNewPassword = agent.password;
    this.user = agent;
    this.modalvisible = true;
    // open modal
    this.elementDisableEnable('#' + agent._id, '#editUser');
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);

    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  @Watch('searchAgent')
  onSearchProjectChanged() {
    if (this.searchAgent == '') {
      this.agtUsrData = [...this.original_data_source_agents];
      return;
    }
    const data_source = this.original_data_source_agents.filter((item: any) => {
      return (
        item.name.firstName
          .toLowerCase()
          .includes(this.searchAgent.toLowerCase()) ||
        item.name.lastName
          .toLowerCase()
          .includes(this.searchAgent.toLowerCase()) ||
        item.username.toLowerCase().includes(this.searchAgent.toLowerCase()) ||
        item.company.name.toLowerCase().includes(this.searchAgent.toLowerCase())
      );
    });
    this.agtUsrData = [...data_source];
  }

  showModalAgentModal(agent: any): void {
    this.agentEditModal = true;
    this.form.password = '';
    this.form.name = agent.name.firstName;
    this.form.lastName = agent.name.lastName;
    this.user = agent;
  }

  handleOkAgentModal(): void {
    this.agentEditModal = false;
    this.changeUserInfo(this.form);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h2',[_vm._v("Agentes")]),_c('button',{staticClass:"btn btn-primary",style:({ fontSize: '0.8rem' }),on:{"click":function($event){return _vm.openAgentCreateModal('newAgentModal')}}},[_vm._v(" Crear nuevo agente ")]),_c('agent-create-form',{attrs:{"EventBus":_vm.EventBus,"company":_vm.userLoged?.company,"elementId":"newAgentModal","role":"AGENT"},on:{"new-agent":_vm.getAgentInfo}})],1),_c('hr'),_c('div',{staticClass:"row m-0"},[_c('agent-card-deck',{attrs:{"agentsData":_vm.agtUsrData,"detailsTarget":"modalAgentDetails","chatsTarget":"conversationsModal"},on:{"details":_vm.showAgentDetails,"chats":_vm.showAgentConversations}}),_c('agent-details',{attrs:{"EventBus":_vm.EventBus,"elementId":"modalAgentDetails","user":_vm.user,"agentDetails":_vm.agentDetails,"projectAgent":_vm.projectAgent,"projectTopics":_vm.projectTopics,"agentConversations":_vm.agentConversationActive,"loadingInfo":_vm.loadingInfo},on:{"topic-checked":_vm.suscribeAgent,"topic-unchecked":_vm.unSuscribeAgent}}),(_vm.agentDetails._id !== '')?_c('conversation-transfer',{attrs:{"agents":_vm.agtUsrData,"fromAgent":_vm.agentDetails,"elementId":"transferConversationModal"}}):_vm._e(),_c('conversation-list-modal',{attrs:{"currentConversation":_vm.currentConversation,"currentCloseConversations":_vm.currentCloseConversations,"elementId":"conversationsModal","autoDisable":false,"EventBus":_vm.EventBus,"agentConversations":_vm.agentConversations},on:{"close":function($event){_vm.modalConversation = false}}}),(!_vm.isEmpty(_vm.agentDetails) && _vm.agentDetails != undefined)?_c('div',{staticClass:"modals-list",style:({ fontSize: '0.9rem' })},[_c('pop-confirm',{attrs:{"text":_vm.agentDetails.disable
        ? '¿Está seguro que desea deshabilitar a este agente?'
        : '¿Está seguro que desea habilitar a este agente?',"autoDisable":false,"elementId":"modalAgentDetailsConfirm","parentElementId":"modalAgentDetails"},on:{"ok":function($event){return _vm.disableAgent(
            _vm.agentDetails._id,
            _vm.agentDetails.projects[0],
            _vm.agentName,
            !_vm.agentDetails.disable
          )}}}),_c('pop-confirm',{attrs:{"text":_vm.msg,"disableEnable":false,"autoDisable":false,"elementId":"modalAgentDeleteConfirm","parentElementId":"modalAgentDetails"},on:{"ok":function($event){return _vm.deleteAgent(
          _vm.agentDetails._id,
          _vm.agentDetails.projects[0],
          _vm.agentName
        )}}}),_c('user-name-modal',{attrs:{"elementId":"editUser","parentElementId":"modalAgentDetails","autoDisable":false,"agentNewName":_vm.agentNewName,"agentNewLastName":_vm.agentNewLastName,"userId":_vm.agentDetails.user},on:{"ok":_vm.changeUserInfo}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import StateButton from '@/app/components/shared/state-button/StateButton.vue';
import ConversationsList from '@/app/components/shared/conversations-list/ConversationsList.vue';
import CheckBox from '@/app/components/shared/check-box/CheckBox.vue';
import { Prop } from 'vue-property-decorator';
import $ from 'jquery';
import { Agent } from '@/app/store/modules/admin/admin.models';

@Component({
  name: 'agent-details',
  components: { StateButton, ConversationsList, CheckBox },
})
export default class AgentDetails extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop() readonly user!: any;
  @Prop() readonly agentDetails!: Agent;
  @Prop() readonly projectAgent!: any;
  @Prop() readonly projectTopics!: any;
  @Prop() readonly agentConversations!: number;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string;
  @Prop() readonly loadingInfo!: boolean;

  getCheckedStatus(item: any, name: string): boolean {
    return item.subscriptions.some((subscription: string) => {
      const parts = subscription.split('_');
      const unless = parts.slice(1).join('_');
      return name === unless;
    });
  }

  checkTopic(topic: any, agent: any) {
    if (!this.agentDetails.disable) {
      this.$emit('topic-checked', {
        topic: topic,
        agent: agent,
      });
    }
  }

  unCheckTopic(topic: any, agent: any) {
    if (!this.agentDetails.disable) {
      this.$emit('topic-unchecked', {
        topic: topic,
        agent: agent,
      });
    }
  }

  isEmpty(obj: any): boolean {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);

    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  changeName() {
    if (!this.agentDetails.disable) {
      this.elementDisableEnable('#' + this.elementId, '#editUser');
    }
  }
}

import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AgentCard from '@/app/components/agents-management/dedicated-components/agent-card/AgentCard.vue';

@Component({
  name: 'agent-card-deck',
  components: { AgentCard },
})
export default class AgentCardDeck extends VueWizard {
  @Prop() readonly agentsData!: any;
  @Prop() readonly detailsTarget!: string;
  @Prop() readonly chatsTarget!: string;

  showDetails(values: any) {
    this.$emit('details', values);
  }
  showChats(values: any) {
    this.$emit('chats', values);
  }
}

import { VueWizard } from '@/vue-wizard';
import { ConversationNewEntryQueue } from '@/app/store/modules/conversation/conversation.models';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'conversation-list-element.ts',
})
export default class ConversationsList extends VueWizard {
  @Prop() agentConversations!: any;
  @Prop() conversation!: any;

  getUserPicture(item: any): any {
    let profileurl = null;
    let conversation;

    if (item.variables) {
      conversation = item;
    } else {
      conversation = this.agentConversations.find(
        (conv: any) => conv._id == item.conversation,
      );
    }

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE
    ) {
      profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }
    return profileurl;
  }

  getUserName(item: any): any {
    let userName = '';
    let conversation;

    if (item.variables) {
      conversation = item;
    } else {
      conversation = this.agentConversations.find(
        (conv: any) => conv._id == item.conversation,
      );
    }

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }

    return userName;
  }

  getTopicByConversation(item: ConversationNewEntryQueue): string {
    let topic = item.topic
      ? item.topic
      : item.currentTopic
      ? item.currentTopic
      : '';
    if (topic.includes('_')) topic = topic.split('_')[1];
    return topic;
  }
}

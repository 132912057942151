import { VueWizard } from '@/vue-wizard';
import { Component } from 'vue-property-decorator';
import { BASE_URL_MANAGER } from '@/config';
import Axios from 'axios';
import QuickResponseRow from '@/app/components/agents-management/dedicated-components/quick-response-row/QuickResponseRow.vue';
import NewQuickResponseModal from '@/app/components/agents-management/dedicated-components/new-quick-response-modal/NewQuickResponseModal.vue';
import EditQuickResponseModal from '@/app/components/agents-management/dedicated-components/edit-quick-response-modal/EditQuickResponseModal.vue';
import $ from 'jquery';
import Vue from 'vue';
import { IUser } from '@/app/store/modules/user/user.models';
const _ = require('lodash');
import { store } from '@/app/store';

@Component({
  name: 'questions-management',
  components: {
    QuickResponseRow,
    NewQuickResponseModal,
    EditQuickResponseModal,
  },
})
export default class QuestionsManagement extends VueWizard {
  public projects: any = [];
  public project_id = '';
  public topics: any = [];
  public quickResponses: any = [];
  public quickResponsesIds: any = [];
  public fieldToEdit = '';
  public quickResponseToEdit = '';
  public quickResponseModalKey: any = 0;
  public EventBus: Vue = new Vue();
  public userLoged: IUser = store.state.user!.user;

  mounted() {
    this.userLoged = store.state.user!.user;
    if (this.userLoged != null) {
      this.getCurrentProjects();
      this.getTopicsByProject();
      this.getQuickResponses();
    }
  }

  async getCurrentProjects() {
    const url = `${BASE_URL_MANAGER}/project/company/${
      this.userLoged.company!._id
    }`;
    try {
      const res = await Axios.get(url);
      this.projects = res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getTopicsByProject() {
    const url = `${BASE_URL_MANAGER}/topic/${this.project_id}`;
    try {
      const res = await Axios.get(url);
      this.topics = res.data.projectTopics;
      console.log('topics', this.topics);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  async updateProject() {
    try {
      await this.getTopicsByProject();
      await this.getQuickResponses();
    } catch (err) {
      console.error(err);
    }
  }

  async getQuickResponses() {
    const url = `${BASE_URL_MANAGER}/quickResponse/topic/${this.topics[0]._id}`;
    try {
      const res = await Axios.get(url);
      this.quickResponses = res.data;
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
    return false;
  }
  enableQuickResponseModal(id: string) {
    this.EventBus.$emit('reload-modal');
    this.enable(id);
  }

  enable(id: string) {
    $('#' + id).modal('show');
  }

  editField(values: any) {
    let elementId = values.id;
    this.fieldToEdit = values.field;
    this.quickResponseToEdit = _.cloneDeep(values.quickResponse);
    this.enable(elementId);
  }
}

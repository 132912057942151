import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({name: 'state-button'})
export default class StateButton extends VueWizard{

    @Prop() readonly activeState!: boolean;
    @Prop() readonly activeText!: string;
    @Prop() readonly inactiveText!: string;
    @Prop() readonly activeClasses!: Array<string>;
    @Prop() readonly inactiveClasses!: Array<string>

}
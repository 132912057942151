import { render, staticRenderFns } from "./AgentsAdministrator.vue?vue&type=template&id=b1d042fc"
import script from "./agents-administrator.ts?vue&type=script&lang=ts&external"
export * from "./agents-administrator.ts?vue&type=script&lang=ts&external"
import style0 from "./AgentsAdministrator.vue?vue&type=style&index=0&id=b1d042fc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports